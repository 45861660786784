import { Component, OnInit } from '@angular/core';
import {BusinessFormat, ComplainantTypes, ComplaintTypes, Enterprise} from '../enterprise.component';

const BUSINESS_FORMAT: BusinessFormat[] = [
  {id: 1, name: 'Planta'},
  {id: 2, name: 'Departamentos'}
];

const COMPLAINANT_TYPES: ComplainantTypes[] = [
  {id: 1, name: 'Empleado'},
  {id: 2, name: 'Proveedor'}
];

const COMPLAINTTYPES: ComplaintTypes[] = [
  {id: 1, name: 'Acoso'},
  {id: 2, name: 'Perjuria'},
  {id: 3, name: 'Faltas'},
  {id: 4, name: 'Robo'},
  {id: 5, name: 'Abuso confianza'}
];

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})

export class ShowComponent implements OnInit {
  enterprise: {
    name: 'TQE',
    urlImage: 'https://s3-us-west-1.amazonaws.com/repo-tqe-xaws/logo-tqe.png',
    workplaces: [
      {id: 1, name: 'Lomas Altas',
        departments: [
          {id: 1, name: 'Clínica'},
          {id: 2, name: 'Atencion al cliente'},
          {id: 3, name: 'Marketing'}
        ]
      },
      {id: 2, name: 'Copilco',
        departments: [
          {id: 1, name: 'Atención al cliente'}
        ]
      },
      {id: 3, name: 'Chapultepec',
        departments: [
          {id: 1, name: 'Clínica'}
        ]
      }]
  };

  constructor() { }

  ngOnInit() {
  }

}
