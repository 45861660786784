import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConstants} from '../shared/constants';


@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  constructor(private http: HttpClient) {
  }

  getEnterpriseInfo(id) {
    return this.http.get(AppConstants.urlServer + `enterprise/${id}`).toPromise().then(data => {
      return data;
    });
  }

  getURLSigned(type) {
    return this.http.get(AppConstants.urlServer + `File${type}`).toPromise().then(data => {
      return data;
    });
  }

  async getURLSignedForFiles(files) {
    const urlFiles = [];
    if (files) {
      for (const file of files) {
        const fileType = file[1].name.split('.').pop();
        if (fileType !== file[1].name) {
          const urlSigned = await this.getURLSigned('/' + fileType);
          // @ts-ignore
          this.http.put(urlSigned.signedUrl, file[1]).toPromise().then(data => {
            // @ts-ignore
            urlFiles.push(urlSigned.urlfile);
          });
        }
      }
    }
    return urlFiles;
  }


  async getURLSignedForScreenshot(screenshot) {
    const urlSigned = await this.getURLSigned('');
    // @ts-ignore
    return this.http.put(urlSigned.signedUrl, screenshot).toPromise().then(data => {
      // @ts-ignore
      return urlSigned.urlFile;
    });
  }

  async sendComplaint(complaint) {
    const urlFiles = await this.getURLSignedForFiles(complaint.files);
    const urlScreenshot = await this.getURLSignedForScreenshot(complaint.screenshot);

    const finalComplaint = {
      complaintForm: complaint.form,
      files: urlFiles,
      evidenceUrl: urlScreenshot,
    };

    return this.http.post(AppConstants.urlServer + 'complaint', finalComplaint).toPromise().then(resp => {
      return(resp);
    });
  }
}
