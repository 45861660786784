import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {

  @Input() loaded;

  constructor(private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
  }

  ngOnChanges(loaded: any) {
    this.ngxService.start();
    this.loaded === 'true' ? this.ngxService.stop() : this.ngxService.start();
  }

}
