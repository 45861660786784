import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-detail-compliance',
  templateUrl: './detail-complaint.component.html',
  styleUrls: ['./detail-complaint.component.scss']
})
export class DetailComplianceComponent implements OnInit {

  @Input('enterprise-info') enterpriseInfo;
  @ViewChild('filesInput', {static: false}) filesInput: ElementRef;

  public files: FormData;

  constructor(public controlContainer: ControlContainer) {
  }

  ngOnInit() {
  }

  onFileChange(files: FileList) {
    const formData = new FormData();
    // @ts-ignore
    for (const file of files) {
      formData.append('file', file, file.name);
    }
    this.files = formData;
  }

}
