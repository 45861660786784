import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComplaintFormComponent } from './pages/complaint-form/complaint-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from './admin/admin.component';
import { EnterpriseComponent } from './admin/enterprise/enterprise.component';
import { CathegoriesComponent } from './admin/cathegories/cathegories.component';
import { GeneralDataComponent } from './pages/complaint-form/components/general-data/general-data.component';
import { PersonalDataComponent } from './pages/complaint-form/components/personal-data/personal-data.component';
import { DetailComplianceComponent } from './pages/complaint-form/components/detail-complaint/detail-compliance.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule, MatFormFieldModule, MatListModule, MatTableModule} from '@angular/material';
import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ERROR_MESSAGES, NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {CUSTOM_ERRORS} from './shared/custom-errors';
import { ShowComponent } from './admin/enterprise/show/show.component';
import { EditComponent } from './admin/enterprise/edit/edit.component';
import { PrivacyNoticeModalComponent } from './pages/complaint-form/components/privacy-notice-modal/privacy-notice-modal.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER} from 'ngx-ui-loader';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsPosition: POSITION.centerCenter,
  fgsSize: 60,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 0, // progress bar thickness
  fgsType: SPINNER.chasingDots
};

@NgModule({
  declarations: [
    AppComponent,
    ComplaintFormComponent,
    AdminComponent,
    EnterpriseComponent,
    CathegoriesComponent,
    GeneralDataComponent,
    PersonalDataComponent,
    DetailComplianceComponent,
    ShowComponent,
    EditComponent,
    PrivacyNoticeModalComponent,
    LoaderComponent,
  ],
    imports: [
      BrowserModule,
      NgbModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,
      MatTableModule,
      HttpClientModule,
      NgBootstrapFormValidationModule.forRoot(),
      MatExpansionModule,
      MatListModule,
      MatFormFieldModule,
      NgxUiLoaderModule,
      NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ],
  providers: [{
    provide: CUSTOM_ERROR_MESSAGES,
    useValue: CUSTOM_ERRORS,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
