import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CustomDatepickerService,
  I18n
} from '../../../../services/custom-datepicker.service';

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerService},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class GeneralDataComponent implements OnInit, OnChanges {

  @Input('enterprise-info') enterpriseInfo;

  public enterprise: any;
  public template: any;

  constructor(public controlContainer: ControlContainer,
              private config: NgbDatepickerConfig,
              private router: ActivatedRoute) {
    config.minDate = {year: 1900, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};
    this.enterprise = this.router.snapshot.data.enterprise;
  }

  ngOnInit() {
    this.enterprise = this.router.snapshot.data.enterprise;
  }

  ngOnChanges() {
    this.template = this.enterpriseInfo.generalData;
    if (this.enterpriseInfo.length === 0) {
      this.enterpriseInfo = {
        complaintCategory: [
          {
            _id: '5e0e834d8bb3612b025837d9',
            name: 'Acoso Sexual'
          },
          {
            _id: '5e0e834d8bb3612b025837d8',
            name: 'Discriminación'
          },
          {
            _id: '5e0e834d8bb3612b025837d7',
            name: 'Negligencia Laboral'
          },
          {
            _id: '5e0e834d8bb3612b025837d6',
            name: 'Represalia a denuncias'
          },
          {
            _id: '5e0e834d8bb3612b025837d5',
            name: 'Violencia laboral'
          },
          {
            _id: '5e0e834d8bb3612b025837d4',
            name: 'Otro'
          }
        ]
      };
    }
  }

}
