import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ComplaintFormComponent} from './pages/complaint-form/complaint-form.component';
import {AdminComponent} from './admin/admin.component';
import {CathegoriesComponent} from './admin/cathegories/cathegories.component';
import {EnterpriseComponent} from './admin/enterprise/enterprise.component';
import {ShowComponent} from './admin/enterprise/show/show.component';


const routes: Routes = [
  {path: '', redirectTo: '/buzon-tqe', pathMatch: 'full'},
  // {path: 'admin', component: AdminComponent},
  // {path: 'admin/cathegories', component: CathegoriesComponent},
  // {path: 'admin/enterprise', component: EnterpriseComponent},
  // {path: 'admin/enterprise/show', component: ShowComponent},
  {path: 'buzon-tqe/:id', component: ComplaintFormComponent, data: {template: 1}},
  {path: 'complaint/:id', component: ComplaintFormComponent, data: {template: 2}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
