import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { FormBuilder, Validators } from '@angular/forms';
import { ComplaintService } from '../../services/complaint.service';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { b64toBlob } from '../../shared/b64toBlob';
import { DetailComplianceComponent } from './components/detail-complaint/detail-compliance.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-compliance-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})

export class ComplaintFormComponent implements OnInit {

  enterpriseInfo: any = [];
  loaded = true;

  complaintForm = this.fb.group({
    complainerInfo: this.fb.group({
      name: ['', Validators.required],
      gender: ['', Validators.required],
      birthDate: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      workCenter: ['', Validators.required],
      area: [''],
    }),
    businessFormat: [''],
    complainerType: [''],
    complaintCategory: ['', Validators.required],
    incidentDate: ['', Validators.required],
    details: ['', Validators.required],
    witnesses: [''],
    incidentPlace: ['', Validators.required],
    isComplainerAWitness: ['', Validators.required],
    isComplainerAffected: ['', Validators.required],
    aggressors: ['', Validators.required],
    enterprise: ['', Validators.required],
  });

  template: string;
  customEnterpriseThemeClass: string;
  enterpriseId: string;

  constructor(private fb: FormBuilder,
              private complaintService: ComplaintService,
              private route: ActivatedRoute) {
    this.template = this.route.snapshot.data.template;
  }

  // @ts-ignore
  @ViewChild('screen') screen: ElementRef;
  // @ts-ignore
  @ViewChild('canvas') canvas: ElementRef;
  // @ts-ignore
  @ViewChild('downloadLink') downloadLink: ElementRef;
  // @ts-ignore
  @ViewChild(DetailComplianceComponent) child;

  sendComplaint() {
    this.loaded = false;
    html2canvas(this.screen.nativeElement, {y: 20}).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      const b64info = this.canvas.nativeElement.src.replace(/^data:image\/[a-z]+;base64,/, '');
      // @ts-ignore
      const screenshot = b64toBlob(b64info, 'image/png');
      const form = this.complaintForm.getRawValue();
      const complaint = {
        enterpriseId: this.enterpriseId,
        form,
        files: this.child.files,
        screenshot
      };
      this.complaintService.sendComplaint(complaint).then(resp => {
        alert('Se ha enviado su denuncia, Gracias por su participación.');
        this.complaintForm.reset();
        this.complaintForm.controls.enterprise.setValue(this.enterpriseInfo.name);
        this.child.filesInput.nativeElement.value = '';
        this.loaded = true;
      });
    });
  }

  async ngOnInit() {
    this.enterpriseId = this.route.snapshot.paramMap.get('id');

    if (this.enterpriseId) {
      this.enterpriseInfo = await this.complaintService.getEnterpriseInfo(this.enterpriseId);
      /*
            this.enterpriseInfo.template = {
              title: 'Demanda a los perros',
              subtitle: 'Por jotos',
              generalData: {
                title: 'Datos generales mi general',
              },
              personalData: {
                title: 'datos generales mi general',
              },
              detailCompliance: {
                title: 'datos generales mi general',
              }
            };
      */
      this.customEnterpriseThemeClass = await this.enterpriseInfo.name.toLowerCase().trim() + '-enterprise-theme';
      this.complaintForm.controls.enterprise.setValue(this.enterpriseInfo.name);
    }

    if (this.enterpriseInfo.businessFormat && this.enterpriseInfo.businessFormat.length !== 0) {
      this.complaintForm.get('businessFormat').setValidators([Validators.required]);
    }

    if (this.enterpriseInfo.complainerType && this.enterpriseInfo.complainerType.length !== 0) {
      this.complaintForm.get('complainerType').setValidators([Validators.required]);
    }
  }

}
