import { Component, OnInit } from '@angular/core';

export interface BusinessFormat {
  id: number;
  name: string;
}

export interface ComplainantTypes {
  id: number;
  name: string;
}

export interface ComplaintTypes {
  id: number;
  name: string;
}

export interface Department {
  id: number;
  name: string;
}

export interface Workplace {
  id: number;
  name: string;
  departments: Department[];
}

export interface Enterprise {
  id: string;
  name: string;
  urlImage: string;
  businessFormats: BusinessFormat[];
  complainantTypes: ComplainantTypes[];
  complaintTypes: ComplaintTypes[];
  workplaces: Workplace[];
}

const BUSINESS_FORMAT: BusinessFormat[] = [
  {id: 1, name: 'Planta'},
  {id: 2, name: 'Departamentos'}
]

const COMPLAINANT_TYPES: ComplainantTypes[] = [
  {id: 1, name: 'Empleado'},
  {id: 2, name: 'Proveedor'}
];

const COMPLAINTTYPES: ComplainantTypes[] = [
  {id: 1, name: 'Acoso'},
  {id: 2, name: 'Perjuria'},
  {id: 3, name: 'Faltas'},
  {id: 4, name: 'Robo'},
  {id: 5, name: 'Abuso confianza'}
];


const ELEMENT_DATA: Enterprise[] = [
  {id: '1',
    name: 'TQE',
    urlImage: 'https://s3-us-west-1.amazonaws.com/repo-tqe-xaws/logo-tqe.png',
    businessFormats: BUSINESS_FORMAT,
    complainantTypes: COMPLAINANT_TYPES,
    complaintTypes: COMPLAINTTYPES,
    workplaces: [
      {id: 1, name: 'Lomas Altas',
        departments: [
          {id: 1, name: 'Clínica'},
          {id: 2, name: 'Atencion al cliente'},
          {id: 3, name: 'Marketing'}
          ]
      },
      {id: 2, name: 'Copilco',
        departments: [
          {id: 1, name: 'Atención al cliente'}
        ]
      },
      {id: 3, name: 'Chapultepec',
        departments: [
          {id: 1, name: 'Clínica'}
        ]
      }]
  },
  {id: '2',
    name: 'TQE 2',
    urlImage: 'https://s3-us-west-1.amazonaws.com/repo-tqe-xaws/logo-tqe.png',
    businessFormats: BUSINESS_FORMAT,
    complainantTypes: COMPLAINANT_TYPES,
    complaintTypes: COMPLAINTTYPES,
    workplaces: [
      {id: 1, name: 'Lomas Altas',
        departments: [
          {id: 1, name: 'Clínica'},
          {id: 2, name: 'Atencion al cliente'},
          {id: 3, name: 'Marketing'}
        ]
      },
      {id: 2, name: 'Copilco',
        departments: [
          {id: 1, name: 'Atención al cliente'}
        ]
      },
      {id: 3, name: 'Chapultepec',
        departments: [
          {id: 1, name: 'Clínica'}
        ]
      }]
  },
  {id: '3',
    name: 'TQE 3',
    urlImage: 'https://s3-us-west-1.amazonaws.com/repo-tqe-xaws/logo-tqe.png',
    businessFormats: BUSINESS_FORMAT,
    complainantTypes: COMPLAINANT_TYPES,
    complaintTypes: COMPLAINTTYPES,
    workplaces: [
      {id: 1, name: 'Lomas Altas',
        departments: [
          {id: 1, name: 'Clínica'},
          {id: 2, name: 'Atencion al cliente'},
          {id: 3, name: 'Marketing'}
        ]
      },
      {id: 2, name: 'Copilco',
        departments: [
          {id: 1, name: 'Atención al cliente'}
        ]
      },
      {id: 3, name: 'Chapultepec',
        departments: [
          {id: 1, name: 'Clínica'}
        ]
      }]
  }
];

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})

export class EnterpriseComponent implements OnInit {

  constructor() { }

  displayedColumns: string[] = ['name', 'actions']
  dataSource = ELEMENT_DATA;
  ngOnInit() {
  }

}
