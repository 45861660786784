import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CustomDatepickerService,
  I18n
} from '../../../../services/custom-datepicker.service';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerService},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class PersonalDataComponent implements OnInit {

  @Input('enterprise-info') enterpriseInfo;
  @Input('template') template: number;

  public selectedWorkCenter: any;
  public enterprise: any;

  constructor(public controlContainer: ControlContainer,
              private config: NgbDatepickerConfig,
              private router: ActivatedRoute) {
    config.minDate = {year: 1900, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};
    this.enterprise = this.router.snapshot.data.enterprise;
  }

  ngOnInit() {
  }

  change($event: Event) {
    // @ts-ignore
    this.selectedWorkCenter = $event.target.value;
  }
}
